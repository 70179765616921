import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { ContactCard } from "../../cards/ContactCard";
import { Section } from "../../layout/Section";
import { Box, Flex, Stack } from "@chakra-ui/react";

export function OrAnyOfUsHere() {
  return (
    <Section pt="50px">
      <Flex
        mt="8"
        gap="8"
        justifyContent="center"
        direction={{
          base: "column",
          lg: "row",
        }}
      >
        <Stack spacing={16}>
          <Box
            display="flex"
            flexDirection={{
              base: "column",
              lg: "row",
            }}
            gap="8"
          >
            <ContactCard
              isVertical
              image={
                <StaticImage
                  src="../../../images/peter.jpg"
                  alt="Peter"
                  placeholder="blurred"
                />
              }
              name={"Peter Hertsbacka"}
              title={"Business Systems Specialist"}
              emailAddress={"peter.hertsbacka@indevit.fi"}
              phoneNumber={"+358 503589269"}
              linkedIn={"https://www.linkedin.com/in/hertsbacka/"}
            />
            <ContactCard
              isVertical
              image={
                <StaticImage
                  src="../../../images/christoffer.jpg"
                  alt="Christoffer"
                  placeholder="blurred"
                />
              }
              name={"Christoffer Smeds"}
              title={"Business Systems Specialist & CEO"}
              emailAddress={"christoffer.smeds@indevit.fi"}
              phoneNumber={"+358 503310905"}
              linkedIn={
                "https://www.linkedin.com/in/christoffer-smeds-45222315/"
              }
            />
            <ContactCard
              isVertical
              image={
                <StaticImage
                  src="../../../images/jonathan.jpg"
                  alt="Jonathan"
                  placeholder="blurred"
                />
              }
              name={"Jonathan Svahn"}
              title={"Software architect & CTO"}
              emailAddress={"jonathan.svahn@indevit.fi"}
              phoneNumber={"+358 509182903"}
              linkedIn={"https://www.linkedin.com/in/j-svahn/"}
            />
          </Box>
          <Box
            display="flex"
            flexDirection={{
              base: "column",
              lg: "row",
            }}
            gap="8"
          >
            <ContactCard
              isVertical
              image={
                <StaticImage
                  src="../../../images/contact-julia.jpg"
                  alt="Julia"
                  placeholder="blurred"
                />
              }
              name={"Julia Glasberg"}
              title={"UI /UX designer"}
              emailAddress={"julia.glasberg@indevit.fi"}
              phoneNumber={"+358 440682868"}
              linkedIn={"https://www.linkedin.com/in/julia-glasberg"}
            />
            <ContactCard
              isVertical
              image={
                <StaticImage
                  src="../../../images/contact-oliver.jpg"
                  alt="Oliver"
                  placeholder="blurred"
                />
              }
              name={"Oliver Guss"}
              title={"Software developer"}
              emailAddress={"oliver.guss@indevit.fi"}
              phoneNumber={"+358 407407068"}
              linkedIn={"https://www.linkedin.com/in/oliver-guss-786a0513a/"}
            />
            <ContactCard
              isVertical
              image={
                <StaticImage
                  src="../../../images/contact-new-colleague.jpg"
                  alt="Our new colleague?"
                  placeholder="blurred"
                />
              }
              name={"Our new colleague?"}
              title={"Get in touch with us"}
              emailAddress={"marica.ost@indevit.fi"}
              location={{
                name: "Fredsgatan 22",
                location:
                  "https://www.google.com/maps/place/Rauhankatu+22,+65100+Vaasa/@63.0937733,21.6195644,20z/data=!4m15!1m8!3m7!1s0x467d6043c5778fe1:0x379650ccf461f504!2sRauhankatu+22,+65100+Vaasa!3b1!8m2!3d63.093724!4d21.6199484!16s%2Fg%2F11c2j02lqt!3m5!1s0x467d6043c5778fe1:0x379650ccf461f504!8m2!3d63.093724!4d21.6199484!16s%2Fg%2F11c2j02lqt?entry=ttu",
              }}
              bottomText="65100 Vasa"
            />
          </Box>
        </Stack>
      </Flex>
    </Section>
  );
}
