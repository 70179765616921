import React from "react";
import { Layout } from "../components/layout/Layout";
import { MetaTags } from "../components/MetaTags";
import { ContactHero } from "../components/sections/contact/ContactHero";
import { OrAnyOfUsHere } from "../components/sections/contact/OrAnyOfUsHere";

function ContactPage() {
  return (
    <Layout
      isContactLinkHidden
      isHeroShort
      heroSection={<ContactHero />}>
      <OrAnyOfUsHere />
    </Layout>
  )
}

export default ContactPage;

export const Head = () => (
  <MetaTags title="Contact us" />
)