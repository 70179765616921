import React from "react";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { ContactCard } from "../../cards/ContactCard";
import { Hero } from "../../texts/Hero";

export function ContactHero() {
  return (
    <Flex
      w="100%"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      paddingX={{
        base: "50px",
        lg: 0,
      }}
      mt={{
        base: "50px",
      }}
      marginX="auto"
      maxW="600px"
      textAlign="center"
    >
      <Stack spacing={16}>
        <Hero>Contact us!</Hero>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          flexGrow={1}
          gap="8"
        >
          <ContactCard
            isVertical
            image={
              <StaticImage
                src="../../../images/marica.jpg"
                alt="Marica"
                placeholder="blurred"
              />
            }
            name={"Marica Öst"}
            title={"Project manager"}
            emailAddress={"marica.ost@indevit.fi"}
            phoneNumber={"+358 401801482"}
            linkedIn={"https://www.linkedin.com/in/marica-%C3%B6st-304319200/"}
          />
          <ContactCard
            isVertical
            image={
              <StaticImage
                src="../../../images/contact-patricia.jpg"
                alt="Patricia"
                placeholder="blurred"
              />
            }
            name={"Patricia Kronman"}
            title={"Project manager"}
            emailAddress={" (Parental leave) "}
            phoneNumber={" - "}
            linkedIn={"https://www.linkedin.com/in/patriciakronman/"}
          />
        </Box>
      </Stack>
    </Flex>
  );
}
